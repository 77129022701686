import { Stack } from '@koob/margaret';
import { Button } from 'components';
import { FieldArray, useField } from 'formik';
import { Fragment, createElement } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { omit } from 'lodash';
import styled, { useTheme } from 'styled-components';
import DateRangePicker from 'components/Fields/DateRangePicker';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createPromotionPeriodFactory } from 'utils/promotions';
import { formatDate } from '../../utils';
import { ExperiencePricingTypeEnum } from '@koob/enums';
import { DatePicker } from './index';
import moment from 'moment';

const AddPeriodButton = styled(Button).attrs({ type: 'button' })`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  color: ${({ theme }) => theme.textLight};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primary};
  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.orangeLighter};
  }
`;

const DayButtonSelector = ({
  day,
  selected,
  disabled = false,
  onSelect = () => {},
}) => {
  return (
    <Button
      type="button"
      variant={selected ? 'primary' : 'simple'}
      role="switch"
      aria-checked={selected}
      disabled={disabled}
      onClick={() => onSelect({ day: day?.prop, value: !selected })}
    >
      {day?.label}
    </Button>
  );
};

const LabelWrapper = styled(Stack).attrs({ as: 'label' })``;

const PeriodFieldInput = ({
  name,
  index,
  disabled = false,
  customPeriodFields,
  required,
  minDate = new Date(),
}) => {
  const { t } = useTranslation('promotionForm');

  const [state, , { setValue }] = useField(name);
  const [durationDays] = useField('durationDays');
  const [pricingType] = useField('pricingType');

  const days = [
    { prop: 'Mo', label: 'M' },
    { prop: 'Tu', label: 'T' },
    { prop: 'We', label: 'W' },
    { prop: 'Th', label: 'T' },
    { prop: 'Fr', label: 'F' },
    { prop: 'Sa', label: 'S' },
    { prop: 'Su', label: 'S' },
  ];

  const onDaySelect = useCallback(
    ({ day, value: v }) =>
      setValue({
        ...state.value,
        days: {
          ...state.value.days,
          [day]: v,
        },
      }),
    [setValue, state.value],
  );

  const isFitPricing =
    !pricingType?.value || pricingType?.value === ExperiencePricingTypeEnum.FIT;

  return (
    <div>
      <div className="border-b px-6 py-3 flex justify-between items-center">
        <div className="font-medium text-lg">
          <i className="far fa-dot-circle mr-2" />
          {isFitPricing
            ? t('periods.period', { index: index + 1 })
            : t('periods.departure', { index: index + 1 })}
        </div>

        <LabelWrapper>
          <input
            style={{ opacity: 0, width: 0, height: 0, position: 'absolute' }}
            autoFocus
          />

          <div className="flex flex-col justify-center space-y-1">
            {isFitPricing ? (
              <DateRangePicker
                disabled={disabled}
                style={{ width: '100%' }}
                value={[state?.value?.startAt, state?.value?.endAt]}
                onChange={(dates) => {
                  if (dates === null) {
                    setValue(omit(state.value, ['startAt', 'endAt']));
                  } else {
                    const start = dates[0]
                      ? formatDate(dates[0], 'yyyy-MM-dd')
                      : null;
                    const end = dates[1]
                      ? formatDate(dates[1], 'yyyy-MM-dd')
                      : null;
                    setValue({
                      ...state.value,
                      startAt: start,
                      endAt: end,
                    });
                  }
                }}
              />
            ) : (
              <div className="flex space-x-3">
                <DatePicker
                  disabled={disabled}
                  style={{ width: '100%' }}
                  value={state?.value?.startAt}
                  onChange={(date) => {
                    setValue({
                      ...state.value,
                      startAt: date ? moment(date).format('YYYY-MM-DD') : null,
                      endAt: date ? moment(date).add(Math.max(0, durationDays.value - 1), 'days').format('YYYY-MM-DD') : null,
                    });
                  }}
                  minDate={minDate}
                />

                <DatePicker
                  disabled
                  style={{ width: '100%' }}
                  value={state?.value?.endAt}
                />
              </div>
            )}
          </div>
        </LabelWrapper>
      </div>

      <div className="p-6">
        {isFitPricing && (
          <Stack direction="row" alignY="center" style={{ width: '100%' }}>
            <span>
              {t('periods.days')}
              {required && <span className="ml-1 text-red-500">*</span>}
            </span>

            <Stack
              alignY="end"
              alignX="center"
              direction="row"
              gutterSize={0.375}
              style={{ marginLeft: 'auto' }}
            >
              {days.map((day) => (
                <DayButtonSelector
                  disabled={disabled}
                  key={day?.prop}
                  day={day}
                  selected={state.value.days[day.prop]}
                  onSelect={onDaySelect}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {customPeriodFields && (
          <Stack
            direction="row"
            alignY="center"
            style={{ width: '100%' }}
            id="t"
          >
            {createElement(customPeriodFields, { index: index })}
          </Stack>
        )}
      </div>
    </div>
  );
};

const Box = styled(Stack)`
  width: 40px;
  color: ${({ theme }) => theme.textLight};
`;

const PeriodsField = ({
  name,
  disabled = false,
  customPeriodFields,
  required,
  bordered = false,
  minDate,
  canRemoveLastElement,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('promotionForm');
  const [field] = useField(name);

  return (
    <div className={bordered ? 'p-5 border rounded-lg' : ''}>
      <FieldArray
        name={name}
        render={(helpers) => {
          return (
            <>
              <div className="flex-col space-y-3">
                {field?.value?.map((_, index) => (
                  <div className="relative border rounded-lg" key={index}>
                    <PeriodFieldInput
                      name={`${name}.${index}`}
                      index={index}
                      disabled={disabled}
                      customPeriodFields={customPeriodFields}
                      minDate={minDate}
                      required={required}
                    />

                    {index !== 0 || canRemoveLastElement ? (
                      <div className="absolute top-4 -right-[17px] transition bg-white border p-0.5 rounded-full">
                        <Button
                          disabled={disabled}
                          type="button"
                          variant="secondary"
                          onClick={() => helpers.remove(index)}
                          style={{
                            padding: theme.spacing(0.25),
                            margin: 'auto',
                          }}
                        >
                          <IoTrashOutline color="red" size={20} />
                        </Button>
                      </div>
                    ) : (
                      <Box />
                    )}
                  </div>
                ))}
              </div>

              <AddPeriodButton
                style={{
                  marginTop: theme.spacing(0.75),
                  padding: '12px 0',
                  width: '100%',
                }}
                onClick={() => helpers.push(createPromotionPeriodFactory())}
              >
                <i className="far fa-circle-plus mr-1" />
                {t('periods.addPeriod')}
              </AddPeriodButton>
            </>
          );
        }}
      />
    </div>
  );
};

export default PeriodsField;
