import React, { useEffect, useRef, useState } from 'react';
import KoobCard from '../../../../components/Koob/KoobCard';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import KoobProgressBar from '../../../../components/Koob/KoobProgressBar';
import { Button } from '@koob/margaret';
import { useTranslation } from 'react-i18next';
import {
  ExperienceTypology,
  getSchema as getTypologySchema,
} from '../Steps/ExperienceTypology';
import {
  ExperienceConfig,
  getSchema as getConfigSchema,
} from '../Steps/ExperienceConfig';
import {
  ExperienceActivities,
  getSchema as getActivitySchema,
} from '../Steps/ExperienceActivities';
import {
  ExperienceItinerary,
  getSchema as getItinerarySchema,
} from '../Steps/ExperienceItinerary';
import {
  ExperienceProgram,
  getSchema as getProgramSchema,
} from '../Steps/ExperienceProgram';
import {
  ExperienceTransferConfig,
  getSchema as getTransferConfigSchema,
} from '../Steps/Transfer/ExperienceTransferConfig';
import {
  ExperienceTransferSpecification,
  getSchema as getTransferSpecSchema,
} from '../Steps/Transfer/ExperienceTransferSpecification';
import {
  ExperienceExtrasAndActivities,
  getSchema as getServiceSchema,
} from '../Steps/ExperienceExtrasAndActivities';
import { SelectField } from '../../../../components/Fields';
import {
  ExperiencePricing,
  getSchema as getPricingSchema,
} from '../Steps/ExperiencePricing';
import { Formik } from 'formik';
import { formatExperienceApiData } from '../Utilities/ExperienceHelpers';
import {
  ExperienceMedia,
  getSchema as getMediaSchema,
} from '../Steps/ExperienceMedia';
import { useNavigate } from 'react-router-dom';
import ExperienceHeader from './ExperienceHeader';
import {
  ExperienceOptions,
  getSchema as getOptionsSchema,
} from '../Steps/ExperienceOptions';
import { LocaleSwitcher } from '../../../../components';
import {
  ExperienceCondition,
  getSchema as getConditionSchema,
} from '../Steps/ExperienceCondition';

export default function ExperiencesWizard({
  initialExperience,
  initialStep,
  handleSave,
  currentLocale,
  setCurrentLocale,
}) {
  const { t } = useTranslation('experiences');
  const navigate = useNavigate();
  const [currentValidationSchema, setCurrentValidationSchema] = useState();
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [maxCurrentStep, setMaxCurrentStep] = useState(initialStep);
  const [experienceToImport, setExperienceToImport] = useState();

  const setNewCurrentStep = step => {
    setMaxCurrentStep(Math.max(Math.max(maxCurrentStep, currentStep), step));
    setCurrentStep(step);
  };

  const formRef = useRef();
  useEffect(() => {
    formRef.current.validateForm();
  }, [currentValidationSchema, formRef]);

  const onSelectExperience = exp => {
    const expToImport = {
      ...exp,
      programs: exp.programs.map(program => {
        return {
          ...program,
          id: undefined,
          pois: program.pois.map(poi => {
            return {
              ...poi,
              id: undefined,
            };
          }),
        };
      }),
    };
    setExperienceToImport(formatExperienceApiData(expToImport, true));
    setCurrentStep(1);
  };

  const initialValues = experienceToImport ?? initialExperience;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={values => {
          return handleSave(values);
        }}
        context={{ activity: initialValues.activity }}
        validateOnMount={true}
        innerRef={formRef}
        enableReinitialize={true}
      >
        {({ isValid, values, submitForm, errors }) => {
          const steps = [
            {
              name: t('steps.typology.name'),
              title: t('steps.typology.title'),
              description: t('steps.typology.description'),
              component: (
                <ExperienceTypology onSelectExperience={onSelectExperience} />
              ),
              schema: getTypologySchema(),
              hidden: true,
              disabled: true,
            },

            ...(values.type === 'Activity' && !initialExperience.id
              ? [
                  {
                    name: t('steps.extraAndActivity.name'),
                    title: t('steps.extraAndActivity.title'),
                    description: t('steps.extraAndActivity.description'),
                    schema: getServiceSchema(),
                    component: <ExperienceExtrasAndActivities />,
                    disabled: true,
                  },
                ]
              : []),
            {
              name: t('steps.general.name'),
              title: t('steps.general.title'),
              description: t('steps.general.description'),
              component: <ExperienceConfig />,
              schema: getConfigSchema(),
            },
            ...(values.type === 'Transfer'
              ? [
                  {
                    name: t('steps.transferConfig.name'),
                    title: t('steps.transferConfig.title'),
                    description: t('steps.transferConfig.description'),
                    component: <ExperienceTransferConfig />,
                    schema: getTransferConfigSchema(),
                  },
                  {
                    name: t('steps.transferSpecification.name'),
                    title: t('steps.transferSpecification.title'),
                    description: t('steps.transferSpecification.description'),
                    component: <ExperienceTransferSpecification />,
                    schema: getTransferSpecSchema(values),
                  },
                ]
              : []),
            ...(values?.type === 'Activity' && !values?.activity?.isExtra
              ? [
                  {
                    name: t('steps.activity.name'),
                    title: t('steps.activity.title'),
                    description: t('steps.activity.description'),
                    schema: getActivitySchema(),
                    component: <ExperienceActivities />,
                  },
                ]
              : []),
            ...(values.type === 'Program'
              ? [
                  {
                    name: t('steps.program.name'),
                    title: t('steps.program.title'),
                    description: t('steps.program.description'),
                    schema: getProgramSchema(),
                    component: <ExperienceProgram />,
                  },
                  {
                    name: t('steps.itinerary.name'),
                    title: t('steps.itinerary.title'),
                    description: t('steps.itinerary.descriptions'),
                    schema: getItinerarySchema(),
                    component: (
                      <ExperienceItinerary currentLocale={currentLocale} />
                    ),
                  },
                ]
              : []),
            {
              name: t('steps.media.name'),
              title: t('steps.media.title'),
              description: t('steps.media.description'),
              component: <ExperienceMedia currentLocale={currentLocale} />,
              schema: getMediaSchema(values),
            },
            {
              name: t('steps.pricing.name'),
              title: t('steps.pricing.title'),
              description: t('steps.pricing.description'),
              component: <ExperiencePricing currentLocale={currentLocale} values={values} />,
              schema: getPricingSchema(values),
            },
            ...(!values?.activity?.isExtra
              ? [
                  {
                    name: t('steps.condition.name'),
                    title: t('steps.condition.title'),
                    description: t('steps.condition.description'),
                    component: <ExperienceCondition />,
                    schema: getConditionSchema(),
                  },
                ]
              : []),
            {
              name: t('steps.options.name'),
              title: t('steps.options.title'),
              description: t('steps.options.description'),
              component: <ExperienceOptions currentLocale={currentLocale} />,
              schema: getOptionsSchema(),
            },
          ];
          values?.type &&
            setCurrentValidationSchema(steps[currentStep]?.schema);
          let experienceFullValid = true;
          steps.forEach(step => {
            if (step.schema) {
              experienceFullValid =
                experienceFullValid && step.schema.isValidSync(values);
            }
          });
          return (
            <>
              {currentStep > 0 && (
                <>
                  <ExperienceHeader
                    currentLocale={currentLocale}
                    showExportOptions
                    values={values}
                    rightElement={
                      <SelectField
                        label={t('state.title')}
                        name="state"
                        valueField={'value'}
                        options={[
                          { label: t('state.available'), value: 'available' },
                          {
                            label: t('state.inProgress'),
                            value: 'in_progress',
                          },
                          { label: t('state.standBy'), value: 'stand_by' },
                        ]}
                      />
                    }
                  />

                  <KoobCard className="mb-5">
                    <div className="mb-2 flex justify-between">
                      {steps.map(
                        (step, index) =>
                          !step.hidden && (
                            <div className="text-center">
                              {isValid &&
                              !step.disabled &&
                              (index <= maxCurrentStep ||
                                (initialExperience.id &&
                                  index !== 0 &&
                                  (!steps[index - 1].schema ||
                                    steps[index - 1].schema.isValidSync(
                                      values,
                                    )))) ? (
                                <button
                                  key={index}
                                  onClick={() => setNewCurrentStep(index)}
                                  className="border-none font-medium"
                                >
                                  {step.name}
                                </button>
                              ) : (
                                <div className="font-medium opacity-75 cursor-not-allowed">
                                  {step.name}
                                </div>
                              )}
                            </div>
                          ),
                      )}
                    </div>
                    <KoobProgressBar
                      percentage={(currentStep / (steps.length - 1)) * 100}
                    />
                  </KoobCard>
                </>
              )}

              <>
                <KoobCard>
                  <div className="flex justify-center relative">
                    <div className="text-center">
                      <KoobTitle>{steps[currentStep]?.title}</KoobTitle>
                      <div className="text-lg font-medium text-gray-600">
                        {steps[currentStep]?.description}
                      </div>
                    </div>

                    {initialExperience.id && (
                      <div className="absolute top-0 right-0">
                        <LocaleSwitcher
                          queryLocale={currentLocale}
                          updateQueryLocale={setCurrentLocale}
                        />
                      </div>
                    )}
                  </div>

                  <div>{steps[currentStep]?.component}</div>

                  <div className="my-5 flex justify-between px-8">
                    <Button
                      variant="primary"
                      onClick={() => setNewCurrentStep(currentStep - 1)}
                      disabled={
                        steps[currentStep - 1]?.disabled ||
                        !isValid ||
                        currentStep === 0
                      }
                    >
                      {t('misc:previous')}
                    </Button>
                    <Button
                      variant="primary"
                      disabled={
                        !isValid ||
                        currentStep === 0 ||
                        (!initialExperience.id &&
                          currentStep === 1 &&
                          values?.type === 'Activity') ||
                        (values.state === 'available' && !experienceFullValid)
                      }
                      onClick={() => {
                        submitForm().then(id => {
                          if (!values?.id && id) {
                            navigate(
                              `/experiences/edit/${id}?step=${
                                values?.type === 'Activity'
                                  ? currentStep - 1
                                  : currentStep
                              }`,
                              {
                                replace: true,
                              },
                            );
                          }
                        });
                      }}
                    >
                      {t('misc:save')}
                    </Button>
                    {currentStep < steps.length - 1 && (
                      <Button
                        variant="primary"
                        onClick={() => setNewCurrentStep(currentStep + 1)}
                        disabled={currentStep === steps.length - 1 || !isValid}
                      >
                        {t('misc:next')}
                      </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                      <Button
                        variant="primary"
                        disabled={
                          !isValid ||
                          currentStep === 0 ||
                          (values.state === 'available' &&
                            currentStep !== steps.length - 1)
                        }
                        onClick={() => {
                          submitForm().then(() =>
                            navigate('/experiences', { replace: true }),
                          );
                        }}
                      >
                        {t('misc:finish')}
                      </Button>
                    )}
                  </div>
                </KoobCard>
              </>
            </>
          );
        }}
      </Formik>
    </>
  );
}
